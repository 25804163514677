import { warn } from "./lib/warn.js";
process.env.NODE_ENV === 'development' ? warn(Date.now() < new Date('2023-08-09').getTime(), 'The package `react-instantsearch-hooks` is replaced by `react-instantsearch-core`. The API is the same, but the package name has changed. Please update your dependencies and follow the migration guide: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/react/') : void 0;
export { default as version } from "./version.js";
export * from "./components/Configure.js";
export * from "./components/DynamicWidgets.js";
export * from "./components/Index.js";
export * from "./components/InstantSearch.js";
export * from "./components/InstantSearchServerContext.js";
export * from "./components/InstantSearchSSRProvider.js";
export * from "./connectors/useBreadcrumb.js";
export * from "./connectors/useClearRefinements.js";
export * from "./connectors/useConfigure.js";
export * from "./connectors/useCurrentRefinements.js";
export * from "./connectors/useDynamicWidgets.js";
export * from "./connectors/useGeoSearch.js";
export * from "./connectors/useHierarchicalMenu.js";
export * from "./connectors/useHits.js";
export * from "./connectors/useHitsPerPage.js";
export * from "./connectors/useInfiniteHits.js";
export * from "./connectors/useMenu.js";
export * from "./connectors/useNumericMenu.js";
export * from "./connectors/usePagination.js";
export * from "./connectors/usePoweredBy.js";
export * from "./connectors/useQueryRules.js";
export * from "./connectors/useRange.js";
export * from "./connectors/useRefinementList.js";
export * from "./connectors/useSearchBox.js";
export * from "./connectors/useSortBy.js";
export * from "./connectors/useStats.js";
export * from "./connectors/useToggleRefinement.js";
export * from "./hooks/useConnector.js";
export * from "./hooks/useInstantSearch.js";
export * from "./lib/warn.js";